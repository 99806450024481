import { FC } from "react";

import { Carousel } from "antd";

import styles from "@/pages/LoginPage/LoginPage.module.scss";

import slider1 from "@/assets/intro-slider-images/1.jpg";
import slider2 from "@/assets/intro-slider-images/2.jpg";
import slider3 from "@/assets/intro-slider-images/3.jpg";

const IntroCarousel: FC = () => {
  return (
    <div className={styles.carouselContainer}>
      <Carousel dotPosition="bottom" effect="scrollx" autoplay>
        <div>
          <div
            className={styles.carouselItem}
            style={{
              background: `url(${slider1}) 0% 0% / contain`,
            }}
          />
        </div>
        <div>
          <div
            className={styles.carouselItem}
            style={{
              background: `url(${slider2}) 0% 0% / contain`,
            }}
          />
        </div>
        <div>
          <div
            className={styles.carouselItem}
            style={{
              background: `url(${slider3}) 0% 0% / contain`,
            }}
          />
        </div>
      </Carousel>
    </div>
  );
};

export default IntroCarousel;
